import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function FourOhFour() {
  const router = useRouter();
  useEffect(() => {
    router.replace("/");
  });
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="og:title" content="Sorry,not found..." />
        <meta name="og:description" content="Sorry,not found..." />
        <meta name="description" content="Sorry,not found..." />
        <title>404 | Zeekeez</title>
      </Head>
      <div className="container">
        <div className="row justify-content-center mb-3 d-flex">
          <div
            className="d-flex col-md-6 text-center align-items-center justify-content-center"
            style={{
              background: "url('/assets/img/404.png') center center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              minHeight: "600px",
            }}
          >
            <Link href="/" className="btn btn-dark">
              Go back home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
const text = "";
